import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/en',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/a-propos',
        name: 'About-fr',
        component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
        meta: { lang: 'fr', json: 'aPropos' },
    },
    {
        path: '/en/about',
        name: 'About-en',
        component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
        meta: { lang: 'en', json: 'aPropos' },
    },
    {
        path: '/historique',
        name: 'Historique-fr',
        component: () => import(/* webpackChunkName: "history" */ '@/views/Historique.vue'),
        meta: { lang: 'fr', json: 'historique' },
    },
    {
        path: '/en/history',
        name: 'Historique-en',
        component: () => import(/* webpackChunkName: "history" */ '@/views/Historique.vue'),
        meta: { lang: 'en', json: 'historique' },
    },
    {
        path: '/direction',
        name: 'Direction-fr',
        component: () => import(/* webpackChunkName: "direction" */ '@/views/Direction.vue'),
        meta: { lang: 'fr', json: 'direction' },
    },
    {
        path: '/en/management',
        name: 'Direction-en',
        component: () => import(/* webpackChunkName: "direction" */ '@/views/Direction.vue'),
        meta: { lang: 'en', json: 'direction' },
    },
    {
        path: '/prix-et-distinctions',
        name: 'Prix-fr',
        component: () => import(/* webpackChunkName: "prix" */ '@/views/Prix.vue'),
        meta: { lang: 'fr', json: 'prixEtDistinctions' },
    },
    {
        path: '/en/awards-and-recognition',
        name: 'Prix-en',
        component: () => import(/* webpackChunkName: "prix" */ '@/views/Prix.vue'),
        meta: { lang: 'en', json: 'prixEtDistinctions' },
    },
    {
        path: '/realisations',
        name: 'Realisations-fr',
        component: () => import(/* webpackChunkName: "realisations" */ '@/views/Realisations.vue'),
        meta: { lang: 'fr', json: 'realisations' },
    },
    {
        path: '/en/projects',
        name: 'Realisations-en',
        component: () => import(/* webpackChunkName: "realisations" */ '@/views/Realisations.vue'),
        meta: { lang: 'en', json: 'realisations' },
    },

    {
        path: '/actualite',
        name: 'Actualite-fr',
        component: () => import(/* webpackChunkName: "actualite" */ '@/views/Actualites.vue'),
        meta: { lang: 'fr', json: 'actualite' },
    },
    {
        path: '/en/news',
        name: 'Actualite-en',
        component: () => import(/* webpackChunkName: "actualite" */ '@/views/Actualites.vue'),
        meta: { lang: 'en', json: 'actualite' },
    },
    {
        path: '/actualite/:slug',
        name: 'ActualitesEntry-fr',
        component: () =>
            import(/* webpackChunkName: "actualitesdetails" */ '@/views/Actualitesdetails.vue'),
        meta: { lang: 'fr', json: 'actualitesEntry', slug: true },
    },
    {
        path: '/en/news/:slug',
        name: 'ActualitesEntry-en',
        component: () =>
            import(/* webpackChunkName: "actualitesdetails" */ '@/views/Actualitesdetails.vue'),
        meta: { lang: 'en', json: 'actualitesEntry', slug: true },
    },



    {
        path: '/realisations/:slug',
        name: 'RealisationsEntry-fr',
        component: () =>
            import(/* webpackChunkName: "realisationsdetails" */ '@/views/Realisationdetails.vue'),
        meta: { lang: 'fr', json: 'realisationsEntry', slug: true },
    },
    {
        path: '/en/projects/:slug',
        name: 'RealisationsEntry-en',
        component: () =>
            import(/* webpackChunkName: "realisationsdetails" */ '@/views/Realisationdetails.vue'),
        meta: { lang: 'en', json: 'realisationsEntry', slug: true },
    },
    {
        path: '/services',
        name: 'Services-fr',
        component: () => import(/* webpackChunkName: "services" */ '@/views/Services.vue'),
        meta: { lang: 'fr', json: 'services' },
    },
    {
        path: '/en/services',
        name: 'Services-en',
        component: () => import(/* webpackChunkName: "services" */ '@/views/Services.vue'),
        meta: { lang: 'en', json: 'services' },
    },
    {
        path: '/services/:slug',
        name: 'ServicesEntry-fr',
        component: () =>
            import(/* webpackChunkName: "Servicesdetails" */ '@/views/Servicedetails.vue'),
        meta: { lang: 'fr', json: 'servicesEntry', slug: true },
    },
    {
        path: '/en/services/:slug',
        name: 'ServicesEntry-en',
        component: () =>
            import(/* webpackChunkName: "Servicesdetails" */ '@/views/Servicedetails.vue'),
        meta: { lang: 'en', json: 'servicesEntry', slug: true },
    },
    {
        path: '/carrieres',
        name: 'Carrieres-fr',
        component: () => import(/* webpackChunkName: "carrieres" */ '@/views/Career.vue'),
        meta: { lang: 'fr', json: 'carrieres' },
    },
    {
        path: '/en/careers',
        name: 'Carrieres-en',
        component: () => import(/* webpackChunkName: "carrieres" */ '@/views/Career.vue'),
        meta: { lang: 'en', json: 'carrieres' },
    },
    {
        path: '/postes/:slug',
        name: 'Poste-fr',
        component: () => import(/* webpackChunkName: "poste" */ '@/views/Poste.vue'),
        meta: { lang: 'fr', json: 'postesEntry', slug: true },
    },
    {
        path: '/en/jobs/:slug',
        name: 'Poste-en',
        component: () => import(/* webpackChunkName: "poste" */ '@/views/Poste.vue'),
        meta: { lang: 'en', json: 'postesEntry', slug: true },
    },
    {
        path: '/nous-joindre',
        name: 'Contact-fr',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
        meta: { lang: 'fr', json: 'nousJoindre' },
    },
    {
        path: '/en/contact-us',
        name: 'Contact-en',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
        meta: { lang: 'en', json: 'nousJoindre' },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        // Store.dispatch('resetContent')

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    }, 425)

    next()
})

export default router

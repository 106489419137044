<template>
    <footer class="footer" v-if="pageIsLoaded">
        <div class="contacts" v-if="$route.meta.footer !== true">
            <div class="top">
                <div class="left">
                    <h2 class="title small">{{ $t('Contact') }}</h2>
                    <h3 class="title medium">
                        <span v-html="globals.footer.texteSimple2"></span>
                    </h3>
                </div>
                <div class="right">
                    <a href="#scroll_top" v-scroll-to:-120>
                        <p class="regular-text">{{ $t('Haut de page') }}</p>
                        <svg viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.836185 4.9648L3.40285 2.329L3.40285 14L4.59719 14L4.59719 2.329L7.16385 4.9648L8 4.10634L4 -1.74846e-07L-4.32466e-07 4.10634L0.836185 4.9648Z"
                            />
                        </svg>
                    </a>
                </div>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="social">
                        <a :href="globals.footer.reseaux.facebook" target="_blank">
                            <svg
                                class="social_icon"
                                viewBox="0 0 29 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0ZM16.0113 15.1369V23.0257H12.7473V15.1372H11.1166V12.4187H12.7473V10.7865C12.7473 8.56877 13.6681 7.25 16.2842 7.25H18.4621V9.96882H17.1007C16.0824 9.96882 16.015 10.3487 16.015 11.0578L16.0113 12.4184H18.4775L18.1889 15.1369H16.0113Z"
                                />
                            </svg>
                        </a>
                        <a :href="globals.footer.reseaux.linkedin" target="_blank">
                            <svg
                                class="social_icon"
                                viewBox="0 0 29 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0ZM6.96094 12.0098H10.247V21.8829H6.96094V12.0098ZM10.4634 8.95538C10.442 7.98733 9.74977 7.25 8.62564 7.25C7.50152 7.25 6.7666 7.98733 6.7666 8.95538C6.7666 9.90338 7.47979 10.6619 8.58299 10.6619H8.60399C9.74977 10.6619 10.4634 9.90338 10.4634 8.95538ZM18.3142 11.7778C20.4766 11.7778 22.0977 13.1893 22.0977 16.2218L22.0975 21.8828H18.8117V16.6007C18.8117 15.2739 18.3362 14.3686 17.1466 14.3686C16.2388 14.3686 15.698 14.9789 15.4605 15.5684C15.3737 15.7797 15.3523 16.074 15.3523 16.3691V21.8831H12.0659C12.0659 21.8831 12.1092 12.9364 12.0659 12.0099H15.3523V13.4084C15.7885 12.7361 16.5696 11.7778 18.3142 11.7778Z"
                                />
                            </svg>
                        </a>
                        <a :href="globals.footer.reseaux.instagram" target="_blank">
                            <svg
                                class="social_icon"
                                viewBox="0 0 29 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0ZM11.3119 6.81332C12.1368 6.77578 12.4004 6.7666 14.5007 6.7666H14.4982C16.5992 6.7666 16.8618 6.77578 17.6867 6.81332C18.51 6.85102 19.0722 6.98136 19.5652 7.17261C20.0744 7.36997 20.5045 7.63419 20.9347 8.06437C21.3649 8.49421 21.6291 8.92567 21.8273 9.43431C22.0174 9.92602 22.1479 10.488 22.1865 11.3113C22.2236 12.1362 22.2333 12.3997 22.2333 14.5C22.2333 16.6003 22.2236 16.8632 22.1865 17.6881C22.1479 18.5111 22.0174 19.0732 21.8273 19.5651C21.6291 20.0736 21.3649 20.505 20.9347 20.9349C20.505 21.365 20.0742 21.6299 19.5657 21.8274C19.0737 22.0187 18.5111 22.149 17.6878 22.1867C16.8629 22.2242 16.6001 22.2334 14.4997 22.2334C12.3996 22.2334 12.1362 22.2242 11.3113 22.1867C10.4881 22.149 9.92602 22.0187 9.43398 21.8274C8.92567 21.6299 8.49421 21.365 8.06453 20.9349C7.63452 20.505 7.37029 20.0736 7.17261 19.5649C6.98153 19.0732 6.85119 18.5112 6.81332 17.688C6.77595 16.8631 6.7666 16.6003 6.7666 14.5C6.7666 12.3997 6.77627 12.136 6.81316 11.3111C6.85022 10.4881 6.98072 9.92602 7.17244 9.43415C7.37061 8.92567 7.63484 8.49421 8.06501 8.06437C8.49486 7.63436 8.92632 7.37013 9.43495 7.17261C9.92667 6.98136 10.4886 6.85102 11.3119 6.81332Z"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M13.8062 8.16014C13.9409 8.15993 14.0858 8.15999 14.2422 8.16006L14.4999 8.16014C16.5648 8.16014 16.8095 8.16755 17.6249 8.2046C18.3789 8.23908 18.7881 8.36507 19.0607 8.47092C19.4216 8.61109 19.6789 8.77865 19.9494 9.04932C20.2201 9.31999 20.3876 9.57777 20.5281 9.93866C20.634 10.2109 20.7601 10.6202 20.7945 11.3742C20.8315 12.1894 20.8396 12.4343 20.8396 14.4982C20.8396 16.562 20.8315 16.8069 20.7945 17.6221C20.76 18.3761 20.634 18.7854 20.5281 19.0576C20.388 19.4185 20.2201 19.6755 19.9494 19.946C19.6788 20.2167 19.4218 20.3842 19.0607 20.5244C18.7884 20.6308 18.3789 20.7564 17.6249 20.7909C16.8097 20.828 16.5648 20.836 14.4999 20.836C12.435 20.836 12.1902 20.828 11.375 20.7909C10.621 20.7561 10.2118 20.6301 9.939 20.5243C9.57811 20.3841 9.32033 20.2165 9.04966 19.9459C8.77899 19.6752 8.61143 19.4181 8.47094 19.057C8.36509 18.7847 8.23894 18.3755 8.20462 17.6215C8.16757 16.8063 8.16016 16.5614 8.16016 14.4962C8.16016 12.4311 8.16757 12.1875 8.20462 11.3722C8.2391 10.6182 8.36509 10.209 8.47094 9.93641C8.61111 9.57551 8.77899 9.31773 9.04966 9.04706C9.32033 8.77639 9.57811 8.60884 9.939 8.46835C10.2116 8.36201 10.621 8.23634 11.375 8.2017C12.0884 8.16948 12.3649 8.15981 13.8062 8.1582V8.16014ZM18.6282 9.44417C18.1159 9.44417 17.7002 9.85936 17.7002 10.3719C17.7002 10.8842 18.1159 11.2999 18.6282 11.2999C19.1405 11.2999 19.5562 10.8842 19.5562 10.3719C19.5562 9.85952 19.1405 9.44385 18.6282 9.44385V9.44417ZM10.5283 14.4998C10.5283 12.3066 12.3064 10.5284 14.4996 10.5283C16.6928 10.5283 18.4705 12.3065 18.4705 14.4998C18.4705 16.693 16.693 18.4704 14.4998 18.4704C12.3065 18.4704 10.5283 16.693 10.5283 14.4998Z"
                                />
                                <path
                                    d="M14.5007 11.9224C15.9242 11.9224 17.0785 13.0764 17.0785 14.5002C17.0785 15.9238 15.9242 17.078 14.5007 17.078C13.0769 17.078 11.9229 15.9238 11.9229 14.5002C11.9229 13.0764 13.0769 11.9224 14.5007 11.9224V11.9224Z"
                                />
                            </svg>
                        </a>
                    </div>
                    <p class="regular-text">
                        <a target="_blank" rel="noopener noreferrer" href="https://academie.stdenisthompson.com/login" style="color: #fff;">Academie St-Denis Thompson</a>
                        <br>
                        {{ globals.footer.titreSimple }}
                    </p>
                </div>
                <div class="right">
                    <a
                        :href="
                            'https://www.google.com/maps/dir//' +
                            globals.footer.informationsDeContact.adresse.replace('<br />', ' ')
                        "
                        target="_blank"
                        class="line_link"
                    >
                        <p class="regular-text">{{ $t('Adresse') }} - {{ $t('Montréal') }}</p>
                        <p class="regular-text link">
                            <span v-html="globals.footer.informationsDeContact.adresse"></span>
                        </p>
                    </a>
                    <a
                        :href="
                            'https://www.google.com/maps/dir//' +
                            globals.footer.informationsDeContact.adresse2.replace('<br />', ' ')
                        "
                        target="_blank"
                        class="line_link"
                    >
                        <p class="regular-text">{{ $t('Adresse') }} - {{ $t('Québec') }}</p>
                        <p class="regular-text link">
                            <span v-html="globals.footer.informationsDeContact.adresse2"></span>
                        </p>
                    </a>
                    <a href="tel:5145236162" class="line_link">
                        <p class="regular-text">{{ $t('Téléphone') }}</p>
                        <p class="regular-text link">
                            <span v-html="globals.footer.informationsDeContact.telephone"></span>
                        </p>
                    </a>
                    <a href="mailto:info@stdenisthompson.com" class="line_link">
                        <p class="regular-text">{{ $t('Courriel') }}</p>
                        <p class="regular-text link">
                            <span v-html="globals.footer.informationsDeContact.courriel"></span>
                        </p>
                    </a>
                </div>
            </div>
        </div>
        <div class="copy">
            <p class="regular-text"><span v-html="globals.footer.texteSimple1"></span></p>
            <a href="https://elmire.ca/" target="_blank" class="right">
                <p class="regular-text">{{ $t('Conception Elmire') }}</p>
                <img src="@/assets/img/elmire.png" alt="Elmire logo" />
            </a>
        </div>
    </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/variables.scss';
</style>

<template>
    <div id="app">
        <NavBar />

        <div class="main-wrapper">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </div>

        <FooterBar />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'

import { initInViewAnimations } from '@/plugins/AnimationsLayer'

export default {
    components: { NavBar, FooterBar },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    this.toogleNavBarStatus()
                    window.addEventListener('scroll', () => {
                        this.toogleNavBarStatus()
                    })
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 425)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.navbar')) {
                return
            }

            if (st >= 25) {
                document.querySelector('.navbar').classList.add('sticky')
            } else {
                document.querySelector('.navbar').classList.remove('sticky')
            }
        },
    },

    created() {
        // NavBar Follow
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    padding-top: 120px;
    @media screen and (max-width: 1160px) {
        padding-top: 84px;
    }
    @media screen and (max-width: 940px) {
        padding-top: 0;
    }
}

img {
    display: block;
    height: auto;
    width: 100%;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
    margin: 0;
    padding: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.555s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.main-wrapper {
    min-height: 100vh;
}
</style>

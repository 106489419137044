import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        locale: {
            locale: null,
            loaded: false,
        },

        content: {
            loading: false,
            ready: false,
            data: null,
        },

        contentIsLoaded: false,

        globals: {
            loaded: false,
            loading: null,
            data: {
                menuItems: [],
                sideLink: {},
                companyEmail: '',
                companyAddress: {},
                companyAddressLabel: '',
                companySocials: [],
                companyPhoneNumber: '',
                footer: [],
            },
        },

        globalsIsLoaded: false,

        seo: {
            title: null,
            titleRaw: {
                1: '',
                2: '',
            },
            description: null,
            descriptionRaw: '',
            keywords: [],
            score: '',
            social: {
                twitter: {
                    handle: '',
                    title: '',
                    imageId: '',
                    description: '',
                },
                facebook: {
                    handle: '',
                    title: '',
                    imageId: '',
                    description: '',
                },
            },
            advanced: {
                robots: [],
                canonical: '',
            },
        },

        urls: {
            en: '',
            fr: '',
        },
    },

    getters: {
        oppositeLocale: state => (state.locale.locale === 'fr' ? 'en' : 'fr'),
        localeLoaded: state => state.locale.loaded,
        locale: state => state.locale.locale,
        getRoute: state => (route, params) => {
            return {
                name: `${route}Page-${state.locale.locale}`,
                ...(params && { params: { ...params } }),
            }
        },

        // CURRENT PAGE
        contentLoading: state => state.content.loading,
        content: state => state.content.data,
        pageReady: state => state.content.ready,

        // HEADER, FOOTER
        globalsLoaded: state => state.globals.loaded,
        globalsLoading: state => state.globals.loading,
        globals: state => state.globals.data,

        seo: state => state.seo,

        urls: state => state.urls,
    },

    actions: {
        async getGlobals({ commit }) {
            commit('GLOBALS_LOADING', true)
            commit('GLOBALS_LOADED', false)
            try {
                const { footer, servicesEntry, postesEntry, realisationsEntry, actualitesEntry } =
                    await Vue.prototype.$craft.getGlobals()
                if ((footer, servicesEntry, postesEntry, realisationsEntry, actualitesEntry)) {
                    commit('SET_GLOBALS', { footer, servicesEntry, postesEntry, realisationsEntry, actualitesEntry })
                    commit('GLOBALS_LOADED', true)
                    return { footer, servicesEntry, postesEntry, realisationsEntry, actualitesEntry }
                }
                throw new Error('Cannot get globals')
            } catch (error) {
                console.error(error)
            } finally {
                commit('GLOBALS_LOADING', false)
            }
        },

        async getContent({ commit, dispatch }, { lang, slug, type, item }) {
            let element = slug

            commit('CONTENT_LOADING', true)

            try {
                const { data } = await Vue.prototype.$craft.getContentBySlug(
                    element,
                    type,
                    lang,
                    item
                )
                if (data) {
                    if (data.seo) {
                        dispatch('setSeo', data.seo)
                    }
                    if (data.urls) {
                        dispatch('setUrls', data.urls)
                    }
                    if (data.page || data.member || data.career || data.media) {
                        commit('SET_CONTENT', data.page || data.member || data.career || data.media)
                        commit('CONTENT_LOADING', false)
                        return data
                    }
                }
                throw new Error('Cannot get content for this page')
            } catch (error) {
                // console.error(error)
                setTimeout(() => {
                    location.href = '/404'
                }, 175)
            }
        },

        resetContent({ commit }) {
            commit('SET_CONTENT', null)
        },

        setContentLoading({ commit }, loading) {
            commit('CONTENT_LOADING', loading)
        },

        setContent({ commit }, payload) {
            commit('SET_CONTENT', payload)
        },

        setLocale({ commit, getters }, locale) {
            commit('SET_LOCALE', locale)
            if (!getters.localeLoaded) {
                commit('SET_LOCALE_LOADED')
            }
        },

        setSeo({ commit }, seo) {
            commit('SET_SEO', seo)
        },

        setUrls({ commit }, urls) {
            commit('SET_URLS', urls)
        },
    },

    mutations: {
        ['GLOBALS_LOADED'](state, payload) {
            state.globals.loaded = payload
            state.globalsIsLoaded = payload
        },

        ['GLOBALS_LOADING'](state, payload) {
            state.globals.loading = payload
        },

        ['SET_GLOBALS'](state, payload) {
            state.globals.data = payload
        },

        ['CONTENT_LOADING'](state, payload) {
            state.content.loading = payload
            state.contentIsLoaded = !payload
        },

        ['SET_CONTENT'](state, payload) {
            state.content.data = payload
        },

        ['SET_LOCALE'](state, locale) {
            state.locale.locale = locale
        },

        ['SET_LOCALE_LOADED'](state) {
            state.locale.loaded = true
        },

        ['SET_SEO'](state, seo) {
            state.seo = { ...seo, description: seo.descriptionRaw, title: seo.titleRaw['1'] }
        },

        ['SET_URLS'](state, urls) {
            state.urls = urls
        },
    },
    modules: {},
})
